<template>
  <b-card>
    <g-form @submit="print">
      <b-row>
        <!-- from date  -->
        <b-col md="6">
          <g-picker :value.sync="selectedItem.fromDate" label-text="fromDate" />
        </b-col>
        <!-- to date  -->
        <b-col md="6">
          <g-picker :value.sync="selectedItem.toDate" label-text="toDate" />
        </b-col>
        <!-- student  -->
        <b-col md="6">
          <student-autocomplete
            ref="autoComlete"
            name="student"
            :value.sync="selectedItem.studentId"
            url="students/getStudentsTaxLookup"
            @change:action="(val) => this.students.push(val)"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button class="mx-1" type="submit" variant="relief-primary">
            <feather-icon class="mr-25" icon="EyeIcon" />
            {{ $t('review') }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>

<script>
import reportMixin from '@/mixin/reportMixin';
import { reportParameters } from '@/libs/acl/Lookups';
import StudentAutocomplete from '@/components/StudentAutoComplete.vue';

export default {
  components: {
    StudentAutocomplete,
  },
  mixins: [reportMixin],
  props: ['id'],
  data() {
    return {
      students: [],
      selectedItem: {
        studentId: null,
        fromDate: null,
        toDate: null,
      },
    };
  },
  beforeMount() {
    this.fiscalYearStart = this.currentYear.startDate;
    this.fiscalYearEnd = this.currentYear.endDate;
  },
  mounted() {
    this.selectedItem.fromDate = this.getDate(this.fiscalYearStart);
    this.selectedItem.toDate = this.getDate(this.fiscalYearEnd);
    this.getData();
  },

  methods: {
    checkDate() {
      if (this.selectedItem.fromDate === null) {
        this.selectedItem.fromDate = this.getDate(this.fiscalYearStart);
      }
      if (this.selectedItem.toDate === null) {
        this.selectedItem.toDate = this.getDate(this.fiscalYearEnd);
      }
    },
    getData() {},
    filterDataBaseOnId(source, compareTo) {
      return source.find((val) => val.id === compareTo);
    },
    preparePrametersForPrint() {
      const selectedStudent = this.filterDataBaseOnId(this.students, this.selectedItem.studentId);
      const allStudents = this.filterDataBaseOnId(reportParameters, 'allStudents');
      this.selectedItem.studentName = this.selectedItem.studentId
                                    ? this.isRight ? selectedStudent.arabicName : selectedStudent.englishName
                                    : this.isRight ? allStudents.arabicName : allStudents.englishName
    },

    print() {
      this.checkDate();
      if (!this.checkForFiscalYearPerm(this.selectedItem.fromDate, this.selectedItem.toDate)) return;
      this.preparePrametersForPrint();
      this.printReport(this.isRight ? 'FinancialTransactionAllocation-ar' : 'FinancialTransactionAllocation-en', this.selectedItem);
    },
  },
};
</script>
