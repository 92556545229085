<template>
  <b-card>
    <g-form @click="print">

      <b-row>
        <!-- from date  -->
        <b-col md="4">
          <g-picker
            :value.sync="selectedItem.fromDate"
            label-text="fromDate"
          />
        </b-col>

        <!-- to date  -->
        <b-col md="4">
          <g-picker
            :value.sync="selectedItem.toDate"
            label-text="toDate"
          />
        </b-col>

        <!-- income  -->
        <b-col
          v-if="type.route === 'incomeTransaction-report'"
          md="4"
        >
          <b-form-group>
            <g-field
              :value.sync="selectedItem.incomeId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="income"
              field="select"
              name="incomeId"
              :options="incomes"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>

        <!-- expense  -->
        <b-col
          v-if="type.route === 'expenseTransaction-report'"
          md="4"
        >
          <b-form-group>
            <g-field
              :value.sync="selectedItem.expenseId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="expense"
              field="select"
              name="expenseId"
              :options="expenses"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>

        <!-- tax Status  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="helper.taxableStatus"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="situationFromTax"
              field="select"
              name="taxStatusId"
              :options="taxesStatus"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>

        <!-- payment Method  -->
        <b-col
          v-if="type.route === 'expenseTransaction-report'"
          md="4"
        >
          <b-form-group>
            <g-field
              :value.sync="helper.paymentMethod"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="Payment Method"
              field="select"
              name="paymentMethod"
              :options="paymentWays"
              label="arabicName"
              @change="() => {
                selectedItem.paymentId = null;
                helper.otherPaymentType = null;
              }"
            />
          </b-form-group>
        </b-col>

        <!-- safe payment  -->
        <b-col
          v-if="type.route === 'expenseTransaction-report' && helper.paymentMethod == 'cash'"
          md="4"
        >
          <b-form-group>
            <g-field
              field="select"
              label="arabicName"
              name="safePayment"
              label-text="safeName"
              :options="safes"
              :dir="isRight ? 'rtl' : 'ltr'"
              :value.sync="selectedItem.paymentId"
            />
          </b-form-group>
        </b-col>

        <!-- other payment  -->
        <b-col
          v-if="type.route === 'expenseTransaction-report' && helper.paymentMethod == 'other'"
          md="4"
        >
          <b-form-group>
            <g-field
              :value.sync="helper.otherPaymentType"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="PaymentMethods"
              field="select"
              name="PaymentMethods"
              :options="payments"
              label="arabicName"
            />
          </b-form-group>
        </b-col>

        <!-- bank method  -->
        <b-col
          v-if="type.route === 'expenseTransaction-report' && helper.paymentMethod == 'other' && helper.otherPaymentType"
          md="4"
        >
          <b-form-group>
            <g-field
              :value.sync="selectedItem.paymentId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="bankName"
              field="select"
              name="bank"
              disabled
              :options="banks"
              label="arabicName"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- review button -->
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button
            class="mx-1"
            variant="relief-primary"
            @click="print('total')"
          >
            <feather-icon
              class="mr-25"
              icon="ListIcon"
            />
            {{ $t("printTotal") }}
          </b-button>

          <b-button
            variant="outline-primary"
            @click="print('detail')"
          >
            <feather-icon
              class="mr-25"
              icon="LoaderIcon"
            />
            {{ $t("printDetails") }}
          </b-button>
        </b-col>
      </b-row>

    </g-form>
  </b-card>
</template>

<script>
import reportMixin from '@/mixin/reportMixin';
import { reportParameters, taxesStatus, paymentWays } from '@/libs/acl/Lookups'

export default {
  mixins: [reportMixin],
  props: {
    type: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      incomes: [],
      expenses: [],
      safes: [],
      banks: [],
      payments: [],
      taxesStatus: taxesStatus,
      paymentWays: paymentWays,
      selectedItem: {
        incomeId: null,
        expenseId: null,
        isTaxable: null,
        paymentMethodId: null,
        paymentId: null,
        fromDate: null,
        toDate: null
      },
      helper: {
        taxableStatus: null,
        otherPaymentType: null,
        paymentMethod: null,
      }
    };
  },

  watch: {
    'helper.otherPaymentType'(newVal) {
      this.selectedItem.paymentId = newVal
                                  ? this.payments.find((v) => v.id === newVal).bankId
                                  : null;
    },
  },
  beforeMount() {
    this.fiscalYearStart = this.currentYear.startDate;
    this.fiscalYearEnd = this.currentYear.endDate;
  },
  mounted() {
    this.selectedItem.fromDate = this.getDate(this.fiscalYearStart)
    this.selectedItem.toDate = this.getDate(this.fiscalYearEnd)
    this.getPayments();
    this.getData();
  },

  methods: {
    checkDate() {
      if (this.selectedItem.fromDate === null) {
        this.selectedItem.fromDate = this.getDate(this.fiscalYearStart);
      }
      if (this.selectedItem.toDate === null) {
        this.selectedItem.toDate = this.getDate(this.fiscalYearEnd);
      }
    },
    getData() {
      this.get({ url: 'incomes' }).then((data) => {
        this.incomes = data;
      })
      .then(() => {
        this.get({ url: 'expenses' }).then((data) => {
          this.expenses = data;
        });
      })
    },

    getPayments() {
      this.get({ url: 'Safes' }).then((data) => {
        this.safes = data;
      });
      this.get({ url: 'Banks' }).then((data) => {
        this.banks = data;
      });
      this.get({ url: 'PaymentMethods' }).then((data) => {
        this.payments = data;
      });
    },

    filterDataBaseOnId(source, compareTo) {
      return source.find((val) => val.id === compareTo)
    },

    preparePrametersForPrint() {
      // for taxes
      switch (this.helper.taxableStatus) {
        case 'taxable':
          this.selectedItem.isTaxable = true
          this.selectedItem.taxStatus = this.isRight
                                      ? this.filterDataBaseOnId(this.taxesStatus, 'taxable').arabicName
                                      : this.filterDataBaseOnId(this.taxesStatus, 'taxable').englishName
          break;

        case 'nonTaxable':
          this.selectedItem.isTaxable = false
          this.selectedItem.taxStatus = this.isRight
                                      ? this.filterDataBaseOnId(this.taxesStatus, 'nonTaxable').arabicName
                                      : this.filterDataBaseOnId(this.taxesStatus, 'nonTaxable').englishName
          break;

        default:
          this.selectedItem.isTaxable = null
          this.selectedItem.taxStatus = this.$t('all')
          break;
      }

      // for income
      if (this.type.route === 'incomeTransaction-report') {
        const selectedIncome = this.filterDataBaseOnId(this.incomes, this.selectedItem.incomeId);
        const allIncomes = this.filterDataBaseOnId(reportParameters, 'allIncomes');
        this.selectedItem.incomeName = this.selectedItem.incomeId
                                     ? this.isRight ? selectedIncome.arabicName : selectedIncome.englishName
                                     : this.isRight ? allIncomes.arabicName : allIncomes.englishName;
      } else if (this.type.route === 'expenseTransaction-report') {
        // for expense
        const selectedExpense = this.filterDataBaseOnId(this.expenses, this.selectedItem.expenseId);
        const allExpenses = this.filterDataBaseOnId(reportParameters, 'allExpenses');
        this.selectedItem.expenseName = this.selectedItem.expenseId
                                      ? this.isRight ? selectedExpense.arabicName : selectedExpense.englishName
                                      : this.isRight ? allExpenses.arabicName : allExpenses.englishName;

        // for payment
        const allPayments = this.filterDataBaseOnId(reportParameters, 'allPaymentMethods');
        this.selectedItem.paymentMethodName = this.helper.paymentMethod
                                          ? this.helper.paymentMethod === 'cash' ? this.$t('cash') : this.$t('bankAccount')
                                          : this.isRight ? allPayments.arabicName : allPayments.englishName;

        if (this.helper.paymentMethod) {
          if (this.helper.paymentMethod === 'cash') {
            const selectedSafe = this.filterDataBaseOnId(this.safes, this.selectedItem.paymentId);
            const allSafes = this.filterDataBaseOnId(reportParameters, 'allSafes');
            this.selectedItem.paymentMethodId = 1
            this.selectedItem.paymentName = this.selectedItem.paymentId
                                          ? this.isRight ? selectedSafe.arabicName : selectedSafe.englishName
                                          : this.isRight ? allSafes.arabicName : allSafes.englishName
          } else {
            const selectedBank = this.filterDataBaseOnId(this.banks, this.selectedItem.paymentId);
            const allBanks = this.filterDataBaseOnId(reportParameters, 'allBanks');
            this.selectedItem.paymentMethodId = 2
            this.selectedItem.paymentName = this.selectedItem.paymentId
                                          ? this.isRight ? selectedBank.arabicName : selectedBank.englishName
                                          : this.isRight ? allBanks.arabicName : allBanks.englishName
          }
        } else {
          this.selectedItem.paymentMethodId = null
          this.selectedItem.paymentName = this.isRight
                                        ? this.filterDataBaseOnId(reportParameters, 'allBanksAndSafes').arabicName
                                        : this.filterDataBaseOnId(reportParameters, 'allBanksAndSafes').englishName;
        }
      }
    },

    getReportName(reportType) {
      switch (reportType) {
        case 'total':
          return this.type.route === 'expenseTransaction-report'
               ? this.isRight
                  ? 'ExpensesTransactionsTotal-ar'
                  : 'ExpensesTransactionsTotal-en'
               : this.isRight
                  ? 'IncomesTransactionsTotal-ar'
                  : 'IncomesTransactionsTotal-en';

        case 'detail':
          return this.type.route === 'expenseTransaction-report'
               ? this.isRight
                 ? 'ExpensesTransactionsDetails-ar'
                 : 'ExpensesTransactionsDetails-en'
               : this.isRight
                 ? 'IncomesTransactions-ar'
                 : 'IncomesTransactions-en';

        default:
          return ''
      }
    },

    print(type) {
      this.checkDate();
      if (!this.checkForFiscalYearPerm(this.selectedItem.fromDate, this.selectedItem.toDate)) return;
      this.preparePrametersForPrint();
      const repName = this.getReportName(type);
      this.printReport(repName, this.selectedItem);
    },
  },
};
</script>
